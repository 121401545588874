
import {
  AppBar,
  Toolbar,
  Button,
  Drawer,
  Grid,
  MenuItem,
  Container,
  Typography,
  Box,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import Badge from '@mui/material/Badge';
import { Hidden } from '@mui/material';
import Popover from '@mui/material/Popover';
import { display, maxWidth, minWidth, padding, styled, width } from "@mui/system";
import React, { useState, useEffect, useRef } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Logo from "./../../component/Logo";
import Menu from '@mui/material/Menu';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { FaShoppingCart } from "react-icons/fa";
import MenuIcon from '@mui/icons-material/Menu';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';
import { MdFeaturedPlayList } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { getProfiledata, selectProfile } from 'src/service/Features/Api/ProfileSlice';
import { totalCartItem } from "src/service/Features/Cart/CartSelector";
import { MdDashboard } from "react-icons/md";
import { AiOutlineLogout } from "react-icons/ai";
import LogoutModal from "src/component/LogoutModal";
import { FaUserCircle } from "react-icons/fa";
import SearchBox from 'src/component/SearchBox';

const StyledTopbar = styled("Box")(({ theme }) => ({

  "& .menuButton": {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "500",
    textDecoration: "none",
    borderRadius: 0,
    minWidth: "auto",
    color: "#404040",
    height: "31px",
    padding: "0px 7px",
    letterSpacing: "1px",
    marginLeft: "15px",
    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#404040",
      padding: "15px !important",
      height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "&.active": {
      color: "#3b54a5",
      fontWeight: "600",

    },
    "&:hover": {
      color: "#3b54a5",
    },
  },
  "& .toolbar": {
    display: "flex",
    padding: "10px",
    justifyContent: "space-between",
    height: "100%",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
    " .toolbar_txt": {
      display: "flex",
      "& h6": {
        color: "#3E4D43",
        paddingLeft: "10px",

      }
    }
  },
  "& .customefullwidth": {
    width: "100%",
    padding: "0px 50px 0px 50px"
  },



  "$ .containerHeight": {
    height: "100%",
  },
  "$ .containerHeight2": {
    height: "100%",
    backgroundColor: "#3e5244"
  },
  "& .mainHeader": {
    justifyContent: "space-between",
    padding: "0px",
  },
  "& .flexbxtb": {
    display: "flex",
    justifyContent: "flex-end", alignItems: "center",
    whitespace: "nowrap",
    "& .cstm_btn": {
      color: "#FFBB55",
      "&:hover": {
        color: "#3E4D43",
      },
    },
    "& .shoping_icone": {
      paddingLeft: "8px",
      fontSize: "24px",
      cursor: "pointer",
      "& .MuiBadge-badge": {
        backgroundColor: "#3b54a5",
        color: "#fff"
      }
    }
  },
  "& .MuiPaper-root-MuiDrawer-paper": {
    backgroundColor: "#dbdce1",
  },
  "& .smallboxcss": {
    padding: "10px 20px 10px 20px"
  }
}));
const StyledSecondTop = styled("Box")(({ theme }) => ({
  "& .cstm_btncss_main": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    "& .input_base": {
      width: "100%",
      padding: "0px 40px 0px 40px"
    },
    "& .cstm_btncss": {
      "& .butt": {
        backgroundColor: "#3b54a5 !important",
        borderRadius: "20px",
        padding: "4px 8px",
      },
    },
    "& .menubtn": {
      display: "flex",
      justifyContent: "start",
      lineHeight: "36px",
      "a": {
        textDecoration: "none",
        color: "#1c1c1c",
        "&:hover": {
          color: "#3b54a5",
        }
      }
    }

  },
  "& .shoping_icone": {
    "span": {
      fontSize: "24px",
      // backgroundColor: "#3b54a5 !important",
      // borderRadius: "20px"
      cursor: "pointer",
    },
    ".MuiBadge-badge": {
      fontSize: "14px",
      backgroundColor: "#3b54a5 !important",
      borderRadius: "20px",
      color: "#fff"
    }
  },
  "& .phoneinput": {
    padding: '1px 4px',
    display: 'flex',
    alignItems: 'center',
    height: '35px',
    // maxWidth: '150px',
    // "@media (max-width: 390px)": {
    //   maxWidth: '150px !important',
    // },
  },
  "& .drawerContainer": {
    padding: "50px"
  },

  "& .MuiInputBase-root": {
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    }
  }

}));
const StyledSecondT = styled("Box")(({ theme }) => ({
  "& .shoping_icone": {
    "span": {
      fontSize: "24px",
      // backgroundColor: "#3b54a5 !important",
      // borderRadius: "20px"
      cursor: "pointer",
    },
    ".MuiBadge-badge": {
      fontSize: "14px",
      backgroundColor: "#3b54a5 !important",
      borderRadius: "20px",
      color: "#fff"
    }
  },
}));



const headersData = [
  {
    label: "Men’s",
    href: "/means",
  },
  {
    label: "Women’s",
    href: "/womens",
  },
  {
    label: "Kids",
    href: "/kids",
  },
  {
    label: "Traditional Wear",
    href: "/traditionalwear",
  },


];
const headersData2 = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "About Us",
    href: "/aboutus",
  },
  {
    label: "Blogs",
    href: "/blogs",
  },

];


export default function TopBar() {

  const history = useHistory();
  const dispatch = useDispatch();
  const get_profile = useSelector(selectProfile);
  let state1 = useSelector((state) => state);
  let totalItems = totalCartItem(state1);
  function getInitials(firstName, lastName) {
    const firstInitial = firstName && firstName.charAt(0).toUpperCase();
    const lastInitial = lastName && lastName.charAt(0).toUpperCase();
    return firstInitial + lastInitial;
  }
  const firstName = get_profile && get_profile.firstName;
  const lastName = get_profile && get_profile.lastName;
  const username = getInitials(firstName, lastName);
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const [area, setArea] = React.useState("");
  useEffect(() => {
    dispatch(getProfiledata());
  }, [dispatch]);
  const handleChange = (event) => {
    setArea(event.target.value);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const { mobileView, drawerOpen } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 910
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
  }, []);


  const [open5, setOpen5] = React.useState(false);

  useEffect(() => {
    if (!window.localStorage.getItem("token")) {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("userType");
    } // eslint-disable-next-line
  }, [window.localStorage.getItem("token")]);



  const logoutHandler = () => {
    history.push("/");
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("userType");
    window.location.reload();
  };


  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };



  const token = window.localStorage.getItem("token");
  const userType = window.localStorage.getItem("userType");


  const displayDesktop = () => {

    return (
      <StyledTopbar>
        <Container maxWidth="lg" className="p-0" style={{ padding: "0px" }} >
          <Toolbar className="toolbar" >
            {femmecubatorLogo}
            <Box className='customefullwidth'>
              <SearchBox />
            </Box>
            {token ? <>
              <Box className="flexbxtb">
                <Avatar
                  style={{ cursor: "pointer" }}
                  id="demo-positioned-button1"
                  aria-controls={open1 ? 'demo-positioned-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open1 ? 'true' : undefined}
                  onClick={handleClick1}
                >
                  <Typography variant='h6' style={{ color: "rgb(59, 84, 165)", fontWeight: 700 }}> {username ? username : "...."}</Typography>

                </Avatar>
                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button1"
                  anchorEl={anchorEl1}
                  open={open1}
                  onClose={handleClose1}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <>
                    <Box className="smallboxcss" style={{ padding: "10px 20px 10px 20px" }}>
                      <Typography variant='h5' style={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        width: "100px",
                        overflow: "hidden"
                      }}>{get_profile && get_profile ? get_profile?.firstName + " " + get_profile?.lastName : "..."}</Typography>
                    </Box>

                    <Box >
                      <Divider />
                    </Box>
                    {userType === "ADMIN" ? <>
                      <MenuItem >
                        <Typography variant='h6' style={{
                          alignItems: "center", display: "flex"
                        }}><MdDashboard /><NavLink style={{ textDecoration: "none", color: "unset" }} to="/dashboard">Dashboard</NavLink >&nbsp;</Typography>
                      </MenuItem>
                    </> : <>
                      <MenuItem >
                        <Typography variant='h6' style={{
                          alignItems: "center", display: "flex"
                        }}><FaUserCircle style={{ fontSize: "18px" }} /><NavLink style={{ textDecoration: "none", color: "unset" }} to="/userprofile">&nbsp;Profile</NavLink>&nbsp;</Typography>
                      </MenuItem>
                      <MenuItem >
                        <Typography variant='h6' style={{
                          alignItems: "center", display: "flex"
                        }}><MdFeaturedPlayList /><NavLink style={{ textDecoration: "none", color: "unset" }} to="/order">&nbsp;My Order</NavLink>&nbsp;</Typography>
                      </MenuItem>
                    </>}

                    <MenuItem onClick={() => setOpen5(true)}>
                      <Typography variant='h6' style={{
                        alignItems: "center", display: "flex"
                      }}><AiOutlineLogout />&nbsp;Logout</Typography>
                    </MenuItem>
                  </>
                </Menu>
                <Box className="shoping_icone">
                  <Badge badgeContent={totalItems}>
                    <FaShoppingCart
                      style={{ cursor: "pointer" }}
                      onClick={(e) => history.push("/cardlist")}

                    />

                  </Badge>
                </Box>
              </Box>
            </>
              : <>
                <Box className="flexbxtb" >
                  <AccountCircleIcon />
                  <Button color="secondary" onClick={() => {
                    history.push("/login")
                  }}>Login</Button>
                  /
                  <Button color="secondary" onClick={() => {
                    history.push("/signup")
                  }}>Register</Button>
                  <Box className="shoping_icone">
                    <Badge badgeContent={totalItems}>
                      <FaShoppingCart
                        style={{ cursor: "pointer" }}
                        onClick={(e) => history.push("/cardlist")}
                      />
                    </Badge>
                  </Box>
                </Box>
              </>}

          </Toolbar>
        </Container >

      </StyledTopbar >

    );
  };
  const displayDesktop2 = () => {
    return (
      <StyledTopbar>
        <Container maxWidth="lg" className="p-0" style={{ padding: "0px", marginTop: "-23px", }} >
          <Toolbar className="toolbar">
            <Box className="toolbar_txt">
              {getMenuButtons()}
            </Box>
            {/* <Box className="toolbar_txt">

            </Box> */}
            <Box style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }} >

              <Box>
                {getMenuButtons2()}
                &nbsp;    &nbsp;
                <Button variant='containedPrimarySmall'>Special Offers</Button>

              </Box>
            </Box>
          </Toolbar>
        </Container>

      </StyledTopbar>

    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <>
        <StyledSecondT>
          <Container maxWidth="lg" className="p-0">
            <Toolbar className='mainHeader' style={{
              display: "flex",
              justifyContent: "space-between"
            }}>
              <Drawer
                {...{
                  anchor: "right",
                  open: drawerOpen,
                  onClose: handleDrawerClose,
                }}
              >
                <div>{femmecubatorLogo}</div>
              </Drawer>

              <div>{femmecubatorLogo}</div>

              {token ? <>
                <Box className="flex_boxbetweenstart">
                  <Avatar
                    style={{ cursor: "pointer" }}
                    id="demo-positioned-button1"
                    aria-controls={open1 ? 'demo-positioned-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open1 ? 'true' : undefined}
                    onClick={handleClick1}
                  >
                    <Typography variant='h6' style={{ color: "rgb(59, 84, 165)", fontWeight: 700 }}> {username ? username : "...."}</Typography>

                  </Avatar>
                  <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button1"
                    anchorEl={anchorEl1}
                    open={open1}
                    onClose={handleClose1}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    <>
                      <Box className="smallboxcss" style={{ padding: "10px 20px 10px 20px" }}>
                        <Typography variant='h5' style={{
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          width: "100px",
                          overflow: "hidden"
                        }}>{get_profile && get_profile ? get_profile?.firstName + " " + get_profile?.lastName : "..."}</Typography>
                      </Box>

                      <Box >
                        <Divider />
                      </Box>
                      {userType === "ADMIN" ? <>
                        <MenuItem >
                          <Typography variant='h6' style={{
                            alignItems: "center", display: "flex"
                          }}><MdDashboard /><NavLink style={{ textDecoration: "none", color: "unset" }} to="/dashboard">Dashboard</NavLink >&nbsp;</Typography>
                        </MenuItem>
                      </> : <>
                        <MenuItem >
                          <Typography variant='h6' style={{
                            alignItems: "center", display: "flex"
                          }}><FaUserCircle style={{ fontSize: "18px" }} /><NavLink style={{ textDecoration: "none", color: "unset" }} to="/userprofile">&nbsp;Profile</NavLink>&nbsp;</Typography>
                        </MenuItem>
                        <MenuItem >
                          <Typography variant='h6' style={{
                            alignItems: "center", display: "flex"
                          }}><MdFeaturedPlayList /><NavLink style={{ textDecoration: "none", color: "unset" }} to="/order">&nbsp;My Order</NavLink>&nbsp;</Typography>
                        </MenuItem>
                      </>}

                      <MenuItem onClick={() => setOpen5(true)}>
                        <Typography variant='h6' style={{
                          alignItems: "center", display: "flex"
                        }}><AiOutlineLogout />&nbsp;Logout</Typography>
                      </MenuItem>
                    </>
                  </Menu> &nbsp; &nbsp;
                  <Box className="shoping_icone">
                    <Badge badgeContent={totalItems}>
                      <FaShoppingCart
                        style={{ cursor: "pointer" }}
                        onClick={(e) => history.push("/cardlist")}

                      />

                    </Badge>
                  </Box>
                </Box>
              </>
                : <>

                  <Box className="flex_boxbetween" style={{}}>
                    <Button color="secondary" onClick={() => {
                      history.push("/login")
                    }}><AccountCircleIcon style={{ fontSize: "18px" }} />&nbsp; Login
                    </Button>/
                    <Button color="secondary" onClick={() => {
                      history.push("/signup")
                    }}>Register</Button>
                    <Box className="shoping_icone">
                      <Badge badgeContent={totalItems} >
                        <FaShoppingCart style={{ fontSize: "18px" }} />
                      </Badge>
                    </Box>
                  </Box>
                </>}

            </Toolbar>
          </Container>

        </StyledSecondT>

      </>
    );
  };
  const displayMobile2 = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Container>
        <StyledSecondTop>
          <Toolbar className="mainHeader">
            <Drawer
              {...{
                anchor: "right",
                open: drawerOpen,
                onClose: handleDrawerClose,
              }}
            >
              <Box className="drawerContainer">
                <Box>{femmecubatorLogo}</Box>



                {token ? <>
                  <Box className="flex_boxbetweenstart">
                    <Avatar
                      style={{ cursor: "pointer" }}
                      id="demo-positioned-button1"
                      aria-controls={open1 ? 'demo-positioned-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open1 ? 'true' : undefined}
                      onClick={handleClick1}
                    >
                      <Typography variant='h6' style={{ color: "rgb(59, 84, 165)", fontWeight: 700 }}> {username ? username : "...."}</Typography>

                    </Avatar>
                    <Menu
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button1"
                      anchorEl={anchorEl1}
                      open={open1}
                      onClose={handleClose1}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      <>
                        <Box className="smallboxcss" style={{ padding: "10px 20px 10px 20px" }}>
                          <Typography variant='h5' style={{
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            width: "100px",
                            overflow: "hidden"
                          }}>{get_profile && get_profile ? get_profile?.firstName + " " + get_profile?.lastName : "..."}</Typography>
                        </Box>

                        <Box >
                          <Divider />
                        </Box>
                        {userType === "ADMIN" ? <>
                          <MenuItem >
                            <Typography variant='h6' style={{
                              alignItems: "center", display: "flex"
                            }}><MdDashboard /><NavLink style={{ textDecoration: "none", color: "unset" }} to="/dashboard">Dashboard</NavLink >&nbsp;</Typography>
                          </MenuItem>
                        </> : <>
                          <MenuItem >
                            <Typography variant='h6' style={{
                              alignItems: "center", display: "flex"
                            }}><FaUserCircle style={{ fontSize: "18px" }} /><NavLink style={{ textDecoration: "none", color: "unset" }} to="/userprofile">&nbsp;Profile</NavLink>&nbsp;</Typography>
                          </MenuItem>
                          <MenuItem >
                            <Typography variant='h6' style={{
                              alignItems: "center", display: "flex"
                            }}><MdFeaturedPlayList /><NavLink style={{ textDecoration: "none", color: "unset" }} to="/order">&nbsp;My Order</NavLink>&nbsp;</Typography>
                          </MenuItem>
                        </>}

                        <MenuItem onClick={() => setOpen5(true)}>
                          <Typography variant='h6' style={{
                            alignItems: "center", display: "flex"
                          }}><AiOutlineLogout />&nbsp;Logout</Typography>
                        </MenuItem>
                      </>
                    </Menu> &nbsp; &nbsp;
                    <Box className="shoping_icone">
                      <Badge badgeContent={totalItems} >
                        <FaShoppingCart
                          style={{ cursor: "pointer", fontSize: "24px" }}
                          onClick={(e) => history.push("/cardlist")}

                        />
                      </Badge>
                    </Box>
                  </Box>
                </>
                  : <>
                    <Box className="flex_boxbetween" pt={3}>
                      <Button color="secondary" onClick={() => {
                        history.push("/login")
                      }}><AccountCircleIcon />&nbsp; Login
                      </Button>/
                      <Button color="secondary" onClick={() => {
                        history.push("/signup")
                      }}>Register</Button>
                      <Box className="shoping_icone_css">
                        <Badge badgeContent={totalItems} className="shopingIconeCss">
                          <FaShoppingCart style={{ fontSize: "20px" }} />
                        </Badge>
                      </Box>
                    </Box>
                  </>}

                <Box style={{ borderBottom: "1px solid #3f51b5", height: "10px" }}></Box>
                <Box className="menubtn">
                  {getMenuButtons()}
                  <Box style={{ borderBottom: "1px solid #3f51b5", height: "10px" }}></Box>
                  {getMenuButtons2()}
                  <Button variant='containedPrimarySmall'>Special Offers</Button>
                </Box>
              </Box>
            </Drawer>
            <Box className="cstm_btncss_main">
              <Box className="cstm_btncss">
                <Button className="butt" aria-describedby={id} onClick={handleClick}>
                  Category
                </Button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <Box>
                    <List>
                      <ListItem> Men’s</ListItem>
                      <ListItem>Women’s</ListItem>
                      <ListItem> Kids</ListItem>
                      <ListItem> Traditional Wear</ListItem>
                      <ListItem> Western Wear</ListItem>
                    </List>
                  </Box>
                </Popover>
              </Box>
              <Box className="input_base">
                <SearchBox />
              </Box>
              <Box>
                <IconButton
                  {...{
                    edge: "start",
                    color: "inherit",
                    "aria-label": "menu",
                    "aria-haspopup": "true",
                    onClick: handleDrawerOpen,
                  }}
                >
                  <MenuIcon
                    style={{ color: "rgb(73 96 171)", fontSize: "30px" }}
                  />
                </IconButton>
              </Box>
              {/* <Box className="ctmcss">
                <Box>
                  <IconButton
                    {...{
                      edge: "start",
                      color: "inherit",
                      "aria-label": "menu",
                      "aria-haspopup": "true",
                      onClick: handleDrawerOpen,
                    }}
                  >
                    <MenuIcon
                      style={{ color: "rgb(73 96 171)", fontSize: "30px" }}
                    />
                  </IconButton>
                </Box>
              </Box>
              <Box className="ctmcss1">
                <Box className="menubtn">
                  {getMenuButtons2()}
                </Box>
              </Box> */}

            </Box>

          </Toolbar>
        </StyledSecondTop>
      </Container >

    );
  };



  const femmecubatorLogo = (
    <Box>
      <NavLink to="/">
        <Logo className="logoImg" />
      </NavLink>
    </Box>
  );

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <NavLink
            exact
            {...{
              key: label,
              color: 'inherit',
              to: href,
              className: "menuButton",
              activeClassName: 'active',
            }}
          >
            {label}
          </NavLink>
        </>
      );
    });
  };
  const getMenuButtons2 = () => {
    return headersData2.map(({ label, href }) => {
      return (
        <>
          <NavLink
            exact
            {...{
              key: label,
              color: 'inherit',
              to: href,
              className: "menuButton",
              activeClassName: 'active',
            }}
          >
            {' '}
            {label}
          </NavLink>
        </>
      );
    });
  };

  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        style={{ border: "none" }}
      >
        <Box style={{ border: "none" }}>
          {mobileView ? displayMobile() : displayDesktop()}
        </Box>
        <Box style={{ backgroundColor: "#fff", border: "none", boxShadow: " rgba(33, 35, 38, 0.1) 0px 10px 10px -10px" }}>
          {mobileView ? displayMobile2() : displayDesktop2()}
        </Box>
        {open5 && (
          <LogoutModal
            open5={open5}
            title="Are you sure want to logout ?"
            // desc="Are you sure want to logout ?"
            handleClose={() => setOpen5(false)}
            logoutHandler={logoutHandler}
          />
        )}
      </AppBar >
    </>
  );
}




