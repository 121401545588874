import React, { useEffect, useState, useContext } from "react";
import { useLocation, matchPath } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
// import ConfirmationDialogBox from "src/component/ConfirmationDialogBox";
import { IoIosLogOut } from "react-icons/io";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { FaUserFriends } from "react-icons/fa";
import { TbMapPinCode } from "react-icons/tb";
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  Button,
  Slide,
} from '@mui/material';
import { maxWidth, styled, width, } from "@mui/system";

import { IoIosMan } from "react-icons/io";
// import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useHistory } from "react-router-dom";
import NavItem from "./NavItem";
import {
  MdDashboard,
  MdProductionQuantityLimits,
  MdOutlineHelpCenter,
} from "react-icons/md";
import { FaClipboardList } from "react-icons/fa";
import { TfiGallery } from "react-icons/tfi";
import { GiMedicalPack } from "react-icons/gi";
import { SiTimescale } from "react-icons/si";
import { IoLogoBitbucket } from "react-icons/io";
import { MdOutlineContentPasteSearch } from "react-icons/md";
import { RiSlideshowLine, RiSettings4Line } from "react-icons/ri";
import { AuthContext } from "src/context/Auth";
import LogoutIcon from '@mui/icons-material/Logout';
import LogoutModal from "src/component/LogoutModal";
const sections = [
  {
    items: [
      {
        title: "Dashboard",
        modules: "",
        icon: MdDashboard,
        href: "/dashboard",
      },
      {
        title: "Product",
        modules: "",
        icon: MdProductionQuantityLimits,
        href: "/add_product",
      },
      {
        title: "My Profile ",
        modules: "",
        icon: AccountCircleIcon,
        href: "/profile",
      },
      {
        title: "Users",
        modules: "",
        icon: FaUserFriends,
        href: "/userlist",
      },
      {
        title: "All Orders",
        modules: "",
        icon: IoLogoBitbucket,
        href: "/allorder",
      },
      {
        title: "Pincodes",
        modules: "",
        icon: TbMapPinCode,
        href: "/pincodelist",
      },
      {
        title: "Delivery boy",
        modules: "",
        icon: IoIosMan,
        href: "/deliveryboy",
      },
      {
        title: "Change Password",
        modules: "",
        icon: RiSettings4Line,
        href: "/updatePpassword",
      },
    ],
  },
];

function renderNavItems({ items, pathname, permission, userType, depth = 0 }) {
  return (
    <StyledDashboard>
      <List disablePadding>
        {items.reduce(
          (acc, item) =>
            reduceChildRoutes({
              acc,
              item,
              pathname,
              depth,
              permission,
              userType,
            }),
          []
        )}
      </List>
    </StyledDashboard>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth,
  permission,
  userType,
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean()}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const StyledDashboard = styled("Box")(({ theme }) => ({
  "& .mobileDrawer": {
    width: 256,
    background: "#fff !important",
    // background: theme.palette.primary.main,
  },
  "& .desktopDrawer": {
    width: 242,
    // top: 80,
    overflow: "hidden",
    borderRight: "1px solid #ff860d5c",
    height: "100%",
    background: "#fff",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    backdropFilter: "blur(44px)",
  },
  "& .avatar": {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  "& .socialIcon": {
    cursor: "pointer",
    marginRight: 5,
  },
  "& .logo1": {
    width: "100%",
    maxWidth: "150px"
  },
  "& .BITBox": {
    // border: "1px solid white",
    // height: "50px",
    background: "#242B66",
    borderRadius: "2px",
  },
  "& .mainBox": {
    padding: "20px",
  },
  "& .Wallet_class": {
    width: "40px",
    height: "40px",
  },
  "& .box1": {
    padding: "10px",
    display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
  },
  "& .yourWallet": {
    fontFamily: "'Inter', sans-serif",
    fontSize: "10px",
    marginLeft: "8px",
  },
  "& .ZeroContent": {
    color: "white",
    fontSize: "22px",
    marginTop: "-12px",
    textAlign: "center",
    paddingBottom: "10px",
    fontFamily: "'Inter'",
    "@media(max-width:1280px)": {
      textAlign: "left",
      marginLeft: "56px",
    },
  },

  "& .logoutButton": {
    position: "absolute",
    bottom: "5px",
    display: "flex",
    justifyContent: "flex-start",
    marginLeft: "13px",
    width: "calc(100% - 26px)",
    textAlign: "center",
    "& .MuiButton-label": {
      justifyContent: "center",
    },
  },

  "& .dailogOpen": {
    borderRadius: "25px",
    padding: "20px",

    "& .MuiDialog-paperWidthSm": {
      padding: "20px 30px",
    },
    "& .MuiPaper-root": {
      color: "#494949",
    },
  },
  "& .dialougTitle": {
    padding: "5px 25px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const history = useHistory();
  const auth = useContext(AuthContext);
  const location = useLocation();
  const permission = auth.userData.permission;
  const [open5, setOpen5] = useState(false);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const logoutHandler = () => {
    history.push("/");
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("userType");
  };


  const dashboardFun = () => {
    const token = window.localStorage.getItem("token");
    if (!token) {
      history.push("/");
    }
  }
  useEffect(() => {
    dashboardFun()
  }, [])
  const content = (
    <StyledDashboard>
      <Box height="100%" display="flex" flexDirection="column">
        <Box height="100%" display="flex" flexDirection="column">
          <Box height="100%" display="flex" flexDirection="column">
            <PerfectScrollbar options={{ suppressScrollX: true }}>
              <Box>
                <Box p={2}>
                  <img
                    src="images/vdlogo.png"
                    alt="image"
                    className="logo1"
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push("/")}
                  />
                </Box>

                {sections.map((section, j) => {
                  let item;
                  item = section.items;
                  return (
                    <List
                      key={`menu${j}`}
                      subheader={
                        <ListSubheader disableGutters disableSticky>
                          {section.subheader}
                        </ListSubheader>
                      }
                    >
                      {renderNavItems({
                        items: item,
                        pathname: location.pathname,
                        permission: permission,
                        userType: auth.userData.userType,
                      })}
                    </List>
                  );
                })}
              </Box>
              <Box className="hoverText">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setOpen5(true)}
                  className="logoutButton"
                >
                  &nbsp;Logout &nbsp; <IoIosLogOut style={{ fontSize: "20px" }} />
                </Button>
              </Box>
            </PerfectScrollbar>
            {open5 && (
              <LogoutModal
                open5={open5}
                title="Are you sure want to logout ?"
                // desc="Are you sure want to logout ?"
                handleClose={() => setOpen5(false)}
                logoutHandler={logoutHandler}
              />
            )}
          </Box>
        </Box>
      </Box>
    </StyledDashboard>
  );

  return (
    <>
      <StyledDashboard>
        <Hidden lgUp>
          <Drawer
            anchor="left"
            classes="mobileDrawer"
            onClose={onMobileClose}
            open={openMobile}
            variant="temporary"
          >
            {/* <img
            src="./images/logo_locus.png"
            alt="image"
            className={classes.logo}
            onClick={() => history.push("/")}
          /> */}
            {content}
          </Drawer>
        </Hidden>
        <Hidden mdDown>
          <Drawer
            style={{ width: "42px" }}
            anchor="left"
            classes="desktopDrawer"
            open
            variant="persistent"
          >
            {content}
          </Drawer>
        </Hidden>
      </StyledDashboard>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
