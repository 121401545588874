// App.js
import { ThemeProvider } from "@mui/material/styles";
import React, { Suspense, useEffect, Fragment } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MomentUtils from "@date-io/moment";
import PageLoading from "./component/PageLoading";
import ButtonCircular from "./component/ButtonCircular";
import { routes } from "./routes";
import { createBrowserHistory } from "history";
import AuthContext from "./context/Auth";
import AuthGuard from "./component/AuthGuard";
// import { createTheme } from "./theme";
import { Toaster } from "react-hot-toast";
import { createCustomTheme } from "./theme/index";
import { jwtDecode } from 'jwt-decode';
const history = createBrowserHistory();


const checkTokenExpiry = (token) => {
  if (!token) return true; // No token means it's expired
  const { exp } = jwtDecode(token);
  const currentTime = Date.now() / 1000;
  return currentTime > exp;
};


function App() {
  const theme = createCustomTheme();

  // useEffect(() => {
  //   const token = localStorage.getItem('token');
  //   if (checkTokenExpiry(token)) {
  //     history.push('/');
  //   }
  // }, [history]);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <LocalizationProvider utils={MomentUtils}>
          <AuthContext history={history}>
            <Router history={history}>
              <Suspense fallback={<><PageLoading /></>}>
                <Switch>
                  {routes.map((route, i) => {
                    const Component = route.component;
                    const Guard = route.guard ? AuthGuard : Fragment;
                    const Layout = route.layout || Fragment;

                    return (
                      <Route
                        exact={route.exact}
                        key={i}
                        path={route.path}
                        render={(props) => (
                          <Guard>
                            <Layout>
                              {route.routes ? (
                                <RenderRoutes data={route.routes} history={history} />
                              ) : (
                                <Component {...props} history={history} />
                              )}
                            </Layout>
                          </Guard>
                        )}
                      />
                    );
                  })}
                </Switch>
              </Suspense>
            </Router>
            <Toaster
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </AuthContext>
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;

function RenderRoutes({ data, history }) {
  return (
    <Suspense fallback={<PageLoading />}>
      <Switch>
        {data.map((route, i) => {
          const Component = route.component;
          const Guard = route.guard ? AuthGuard : Fragment;
          const Layout = route.layout || Fragment;
          return (
            <Route
              exact={route.exact}
              key={i}
              path={route.path}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      <RenderRoutes data={route.routes} history={history} />
                    ) : (
                      <Component {...props} history={history} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}
