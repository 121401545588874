import styled from "@emotion/styled";
import { Box, Typography, Button } from "@mui/material";
import { useHistory } from 'react-router-dom';
import React from "react";
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
const SearchBoxCss = styled("Box")(({ theme }) => ({
    "& .customeinputbox": {
        display: 'flex', alignItems: 'center', height: "40px", padding: "4px 5px 4px",
        // [theme.breakpoints.down("sm")]: {
        //   height: "10px !important",
        // },
    },

}));
const SearchBox = ({ title }) => {
    const history = useHistory();


    return (
        <SearchBoxCss>
            <Paper
                component="form"
                className='customeinputbox'
            // sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', height: " 45px", width: 400 }}
            >

                <InputBase
                    sx={{ ml: 2, flex: 1 }}
                    placeholder="Search for products ..."
                    inputProps={{ 'aria-label': 'Enter Location' }}
                />

                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton type="button" sx={{ p: '10px' }} aria-label="search" style={{ background: " #3B54A5" }}>
                    <SearchIcon style={{ color: " #fff" }} />
                </IconButton>
            </Paper>
        </SearchBoxCss>
    );
};

export default SearchBox;
