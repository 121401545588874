import React from "react";

import { Container, Button, Typography, Grid, Box } from '@mui/material';
import { styled } from "@mui/system";
import { FaFacebook, FaLinkedin } from "react-icons/fa";
import { FaInstagram, FaXTwitter } from "react-icons/fa6";
const StyledFooter = styled("Box")(({ theme }) => ({
  "& .main_footer": {
    backgroundColor: "#3b54a5",
    paddingTop: "60px",
    borderTopRightRadius: "50px",
    borderTopLeftRadius: "50px",
    "& .useful": {
      "h4": {
        color: "#fff",
      }
    },
    "& .main_footer_second": {
      listStyleType: "none",
      "& .footer_ullist": {
        // listStyleType: "none",
        "ul": {
          listStyleType: "none",
          paddingLeft: "0px",
          color: "#ded6d6",
          lineHeight: "34px",
          fontSize: "14px",
          "li": {
            "&:hover": {
              color: " #ffbb55",
              cursor: "pointer",
            },
          },

        },

        "p": {
          color: "#ded6d6",
          lineHeight: "22px",

        }
      },
      "& .social_icons": {
        display: "flex",
        justifyContent: "space-between",
        color: "#fff",
        fontSize: "x-large"
      }

    }

  },


}));

export default function Footer() {
  return (
    <>
      <StyledFooter>
        <Box className="main_footer">
          <Container maxWidth="lg">

            <Box>
              <Box>
                <Grid container spacing={2}>
                  <Grid item lg={2} md={2} sm={4} xs={12}>
                    <Box className="main_footer_second">
                      <Box className="useful">
                        <Typography variant="h4">Useful Links</Typography>
                      </Box>
                      <Box className="footer_ullist">
                        <ul>
                          <li>Home</li>
                          <li>Abouts Us</li>
                          <li>Blogs</li>
                          <li>Offers</li>
                        </ul>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item lg={2} md={2} sm={4} xs={12}>
                    <Box className="main_footer_second">
                      <Box className="useful">
                        <Typography variant="h4">Popular Searches</Typography>
                      </Box>
                      <Box className="footer_ullist">
                        <ul>
                          <li>Men’s</li>
                          <li>Women’s</li>
                          <li>Kids</li>
                          <li>Traditional Wear</li>
                          <li>Western Wear</li>
                          <li>Sports Wear</li>
                        </ul>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item lg={2} md={2} sm={4} xs={12}>
                    <Box className="main_footer_second">
                      <Box className="useful">
                        <Typography variant="h4">Company</Typography>
                      </Box>
                      <Box className="footer_ullist">
                        <ul>
                          <li>Contact us</li>
                          <li>FAQ</li>
                          <li>Terms & Conditions</li>
                          <li>Privacy & Policy</li>
                        </ul>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item lg={2} md={2} sm={4} xs={12}>
                    <Box className="main_footer_second">
                      <Box className="useful">
                        <Typography variant="h4">Policies</Typography>
                      </Box>
                      <Box className="footer_ullist">
                        <ul>
                          <li>Shipping</li>
                          <li>Track Order</li>
                          <li>Cancellation</li>
                          <li>Return</li>
                        </ul>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item lg={2} md={2} sm={4} xs={12}>
                    <Box className="main_footer_second">
                      <Box className="useful">
                        <Typography variant="h4">Contact</Typography>
                      </Box>
                      <Box className="footer_ullist">
                        <Typography variant="body2">GF-43, Augusta Point, Sector-53,
                          Near Golf Course Road
                          Gurgaon HR 122001 INDIA</Typography>
                        <Box pt={2}>
                          <Typography variant="body2">
                            +1 8053943352
                            info@vastradrobe.com
                          </Typography>
                        </Box>

                      </Box>
                    </Box>
                  </Grid>
                  <Grid item lg={2} md={2} sm={4} xs={12}>
                    <Box className="main_footer_second">
                      <Box className="useful" >
                        <img src="/images/footer_logo.png" alt="Logo" style={{ width: "100%", maxWidth: "205px" }} />;

                      </Box>
                      <Box className="social_icons" pt={5}>
                        <a>
                          <Box>
                            <FaFacebook />
                          </Box>
                        </a>
                        <a>
                          <Box>
                            <FaInstagram />
                          </Box>
                        </a>
                        <a>
                          <Box>
                            <FaLinkedin />
                          </Box>
                        </a>
                        <a>
                          <Box>
                            <FaXTwitter />
                          </Box>
                        </a>

                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </Box>
      </StyledFooter>
    </>
  );
}
