import React, { useContext, useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import {
  AppBar,
  Toolbar,
  IconButton,
  Hidden,
  Box,
  Badge,
  Avatar,
  Typography,
  SvgIcon,
} from '@mui/material';
import { styled } from "@mui/system";
import { Menu as MenuIcon } from "react-feather";
import { useHistory } from "react-router-dom";
import { AuthContext } from "src/context/Auth";
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { FaRegUserCircle } from "react-icons/fa";
import { IoMdLogOut } from "react-icons/io";
import LogoutModal from "src/component/LogoutModal";
import { getProfiledata, selectProfile } from 'src/service/Features/Api/ProfileSlice';
import { useSelector, useDispatch } from "react-redux";

const StyledDashboard = styled("Box")(({ theme }) => ({
  "& .root": {
    // backgroundColor: theme.palette.primary.paper,
    "& .MuiPaper-root": {},
  },
  "& .toolbar": {
    height: 80,
    padding: "0 20px",
    background: "#fff",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    backdropFilter: "blur(44px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.only("xs")]: {
      padding: "0 10px",
    },

  },
  "& .muilistover .MuiListItem-root": {
    cursor: "pointer",
    "&:hover": {
      color: "#ffbb55 !important",
    },
  },
  "& .logo": {
    marginRight: theme.spacing(2),
  },
  "& .link": {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  "& .divider": {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useContext(AuthContext);
  useEffect(() => {
    if (!window.localStorage.getItem("token")) {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("userType");
    } // eslint-disable-next-line
  }, [window.localStorage.getItem("token")]);
  const [open5, setOpen5] = useState(false);

  const get_profile = useSelector(selectProfile);

  useEffect(() => {
    dispatch(getProfiledata());
  }, [dispatch]);
  const logoutHandler = () => {
    history.push("/");
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("userType");
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    // <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
    <StyledDashboard>
      <AppBar className="root" color="default" {...rest}>
        <Toolbar className="toolbar">
          {/* <Hidden lgUp> */}
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <SvgIcon fontSize="40px">
              <MenuIcon style={{ color: "#712bc1" }} />
            </SvgIcon>
          </IconButton>
          {/* </Hidden> */}
          {/* <Hidden lgDown> */}
          <Box display="flex" alignItems="center">
            <Box mr={2}>
              <Typography variant="h6">
                {get_profile && get_profile ? get_profile?.firstName : "N/A"}
              </Typography>
              {/* <Typography variant="body1">mukesh@ads247365.com</Typography> */}
            </Box>
            <IconButton aria-describedby={id} onClick={handleClick}>
              <Badge className="namePlace" color="secondary">
                <Avatar src="/images/Profile.png" alt="image" />
              </Badge>
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Box className="muilistover"
                sx={{
                  '& .MuiListItem-root': {
                    alignItems: 'self-start',
                    padding: '14px',
                    cursor: 'pointer',
                    '&:hover': {
                      background: '#ffbb55',
                      // color: '#ffbb55',
                    },
                  },
                  '& .MuiList': {
                    padding: "0px"
                  }
                }}
              >
                <List>
                  <ListItem><FaRegUserCircle />  &nbsp; Profile</ListItem>
                  <ListItem onClick={() => setOpen5(true)}><IoMdLogOut />&nbsp; Logout</ListItem>
                </List>
              </Box>
            </Popover>
          </Box>
          {/* </Hidden> */}
        </Toolbar>
      </AppBar>
      {open5 && (
        <LogoutModal
          open5={open5}
          title="Are you sure want to logout ?"
          // desc="Are you sure want to logout ?"
          handleClose={() => setOpen5(false)}
          logoutHandler={logoutHandler}
        />
      )}
    </StyledDashboard>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => { },
};

export default TopBar;
