import { api_configs } from "src/api-services";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getProfiledata = createAsyncThunk(
    'profiledata/getProfiledata',
    async (payload) => {
        try {
            // Simulating async operation, returning dummy data after a delay
            const res = await axios({
                method: "GET",
                url: api_configs.getProfile,
                headers: {
                    token: window.localStorage.getItem("token")
                }
            });
            if (res.data.responseCode === 200) {
                return res.data.result;
            } else {
                window.localStorage.removeItem("token");
                window.localStorage.removeItem("userType");
                window.location.reload();
            }
            // await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulating delay
            // return cardDetails.data; // Return dummy data directly
        } catch (error) {
            throw error; // Let the rejection handle in createSlice catch this
        }
    }
);

// Slice for products
const profileSlice = createSlice({
    name: 'profiledata',
    initialState: { isLoading: false, products: [], error: null },
    extraReducers: (builder) => {
        builder
            .addCase(getProfiledata.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getProfiledata.fulfilled, (state, action) => {
                state.isLoading = false;
                state.products = action.payload;
                state.error = null;
            })
            .addCase(getProfiledata.rejected, (state, action) => {
                state.isLoading = false;
                state.products = [];
                state.error = action.error.message; // Access error message
            });
    },
});



// Selectors to access state from Redux store
export const selectProfile = (state) => state.profiledata.products;

// Exporting reducers
export default profileSlice.reducer;