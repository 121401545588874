import React, { useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Container, Button, Collapse, ListItem, Box } from '@mui/material';
import { styled } from "@mui/system";
// import {  makeStyles } from "@material-ui/core";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import ExpandLessIcon from "@material-ui/icons/ExpandLess";
// import Logo from "src/component/Logo";

const StyledDashboard = styled("Box")(({ theme }) => ({
  "& .item": {
    display: "block",
    paddingTop: 0,
    paddingBottom: 0,
  },
  "& .itemLeaf": {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
    fontFamily: "'Inter'",
    "&:hover": {
      background: "#c1c9e3"
    }
  },
  "& .button": {
    color: "#969ba1",
    padding: "10px 8px",
    justifyContent: "flex-start",
    display: "inline",

    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
  },
  "& .buttonLeaf": {
    color: "#000000c4",
    fontWeight: "500",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    // borderLeft: "solid 8px transparent",
    borderRadius: 0,
    fontSize: "16px",
    "& .MuiButton-label": {
      padding: "10px",
    },
    "&:hover": {
      "& .MuiButton-label": {
        color: "#fff !important",
        background: "#FF860D",
        padding: "10px",
        borderRadius: "9px",
        fontWeight: theme.typography.fontWeightRegular,

        "& $icon": {
          color: "#fff !important",
          // color: "00e0b0",
        },
      },
    },
    "&.depth-0": {
      "& $title": {
        // fontWeight: theme.typography.fontWeightMedium,
        // color: `${theme.palette.text.primary} !important`,
      },
    },
  },
  "& .icon": {
    display: "flex",
    alignItems: "center",
    marginRight: "10px",
    marginLeft: theme.spacing(1),
    color: "#000000c4",
  },
  "& .title": {
    marginRight: "auto",
  },
  "& .active": {
    "& .MuiButton-label": {
      color: "#fff",
      background: "#FF860D",
      padding: "10px",
      borderRadius: "9px",
      fontWeight: theme.typography.fontWeightRegular,
      "& $title": {
        fontWeight: "600",
        color: "#fff",
      },
      "& $icon": {
        color: "#fff !important",

        // color: `${theme.palette.text.primary} !important`,
      },
    },
  },
}));

const NavItem = ({
  children,
  className,
  depth,
  href,
  icon: Icon,
  info: Info,
  open: openProp,
  title,
  ...rest
}) => {
  // const classes = useStyles();
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 15 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <StyledDashboard>
        <ListItem
          className="item"
          disableGutters
          key={title}
          {...rest}
        >
          <Button
            className="button"
            onClick={handleToggle}
            style={{ paddingLeft: "15px" }}
          >
            {Icon && <Icon className="icon" size="20" />}
            <span className="title">{title}</span>
            {open ? "icon" : "icon1"}
          </Button>
          <Collapse in={open}>{children}</Collapse>
        </ListItem>
      </StyledDashboard>
    );
  }

  return (
    <StyledDashboard >
      <ListItem
        className="itemLeaf"
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          activeClassName="active"
          className={clsx("buttonLeaf", `depth-${depth}`)}
          component={RouterLink}
          exact
          style={style}
          to={href}
        >
          {Icon && <Icon className="icon" size="20" />}
          <span className="title">{title}</span>
          {Info && <Info />}
        </Button>
      </ListItem>
    </StyledDashboard >
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;
