
export const server_base_url = "https://node.api.ads247365.com";
// export const server_base_url = "http://localhost:4000";
// export const server_base_url = "http://127.0.0.1:8000/";

let version = `${server_base_url}/api/v1/user`;
let user = `${version}`;
let version1 = `${server_base_url}/api/v1/admin`;
let admin = `${version1}`;

export const api_configs = {
  // ----------------ADMIN------------------
  addProduct: `${admin}/addProduct`,
  viewProduct: `${admin}/viewProduct`,
  updateProduct: `${admin}/updateProduct`,
  deleteProduct: `${admin}/deleteProduct`,
  updatePassword: `${admin}/updatePassword`,
  userList: `${admin}/userList`,
  deleteUser: `${admin}/deleteUser`,
  add_pincode: `${admin}/add_pincode`,
  deletePincode: `${admin}/deletePincode`,
  searchPincode: `${admin}/searchPincode`,
  allOrderList: `${admin}/allOrderList`,

  // ----------------USER------------------
  signUp: `${user}/signUp`,
  otpVerify: `${user}/otpVerify`,
  login: `${user}/login`,
  listProduct: `${user}/listProduct`,
  upload_image: `${user}/upload_image`,
  address_save: `${user}/address_save`,
  address_list: `${user}/address_list`,
  delete_address: `${user}/delete_address`,
  pincode_list: `${user}/pincode_list`,
  getProfile: `${user}/getProfile`,
  updateProfile: `${user}/updateProfile`,
  orders: `${user}/orders`,
  addPayment: `${user}/addPayment`,
  getPayment: `${user}/getPayment`,
  myOrderlist: `${user}/myOrderlist`,
  viewOrder: `${user}/viewOrder`,
};
